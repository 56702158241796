import React from "react"
import ImageMeta from "../../components/ImageMeta"
import MarkdownViewer from "@components/MarkdownViewer"

function OurMission(props) {
  return (
    <>
      <section className="our-mission">
        <div
          style={{ width: "100%" }}
          className="columns has-text-centered-tablet">
          <div className={`column is-${props.sideColumnIs}`} />
          <MarkdownViewer markdown={props.post.ourMission} />
          <div className={`column is-${props.sideColumnIs}`} />
        </div>
      </section>
    </>
  )
}

export default OurMission
