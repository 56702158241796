import React from "react"
import PropTypes from "prop-types"
import MarkdownViewer from "@components/MarkdownViewer"
import classNames from "classnames"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"

function LearnMoreCTA(props) {
  const { colorBack, post } = props
  const wrapperClassNames = classNames(
    "body-section",
    "section",
    "learn-more-section",
    {
      "color-back": colorBack
    }
  )

  return (
    <section className={wrapperClassNames}>
      <div className="columns has-text-centered-tablet">
        <div className="column is-5" />
        <div className="column">
          <h2 className="h3-style" style={{ marginBottom: "1.5rem" }}>
            {post.learnMore.heading}
          </h2>
        </div>
        <div className="column is-5" />
      </div>
      <div className="columns has-text-centered-tablet">
        <div className="column is-4" />
        <div className="column mobile-col">
          <MarkdownViewer markdown={post.learnMore.blurb} />
        </div>
        <div className="column is-4" />
      </div>
      <div className="columns">
        <div className="column is-4" />
        <div className="column">
          <ButtonGroupMap
            isCentered
            noReverse
            buttons={post.learnMore.buttons}
          />
        </div>
        <div className="column is-4" />
      </div>
    </section>
  )
}

LearnMoreCTA.propTypes = {
  post: PropTypes.object.isRequired,
  colorBack: PropTypes.bool
}

export default LearnMoreCTA
